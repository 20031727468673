import * as React from 'react';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { TransitionLeft } from '../helper/utils';
import { apiFetch } from '../Security/apiFetch';
import RessourcesList from './RessourcesItem/RessourcesList';
import Favoris from './RessourcesItem/Favoris';
import Search from './RessourcesItem/Search';
import ReactGA from 'react-ga';

export default function Ressources({
    zoom,
    setZoom,
    navigateRoute,
    setBreadcrumb,
    setOpenBackdrop,
    setText,
    setSeverity,
    setTransition,
    setOpenSnackbar,
    handleFavoris,
    refreshFavoris,
    phone,
}) {
    document.title = 'Espace Salarié - Ressources'
    const TRACKING_ID = "G-RKY2WWVRSQ"
    ReactGA.initialize(TRACKING_ID)
    const [ressources, setRessources] = React.useState(false)
    const [search, setSearch] = React.useState('')

    React.useEffect(() => {
        setZoom(true)
        setBreadcrumb(['Ressources bien-être'])
        window.scrollTo(0,0)
        ReactGA.pageview(window.location.pathname)
        return () => setZoom(false)
    }, [setZoom, setBreadcrumb])

    const handleSearch = async (e) => {
        e.preventDefault()
        let data = new FormData(e.target)
        const value = data.get('search')
        setOpenBackdrop(true)
        const getRessources = await apiFetch(`/ressources?mot_cle=${value}`)
        if (getRessources) {
            setRessources(getRessources)
            setZoom(true)
        } else {
            setText('Erreur lors de la récupération des ressources !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
        }
        setOpenBackdrop(false)
    }

    const handleDelete = () => {
        setSearch('')
        setRessources(false)
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                    <form noValidate onSubmit={handleSearch} autoComplete="on">
                        <div style={{maxWidth: 1500, margin: 'auto', height: 60, textAlign: 'center'}}>
                            <TextField
                                id="search"
                                label="Rechercher des ressources par mots clés"
                                variant="outlined"
                                name='search'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                sx={{width: 700, mb: 3}}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton type='submit'>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </div>
                    </form>
                </Zoom>
                {!ressources ? <div style={{maxWidth: 1400, margin: 'auto', textAlign: 'center', marginTop: 10}}>
                    <RessourcesList phone={phone} zoom={zoom} navigateRoute={navigateRoute} />
                </div> : 
                <Search
                    search={search}
                    zoom={zoom}
                    ressources={ressources}
                    handleDelete={handleDelete}
                    handleFavoris={handleFavoris}
                    navigateRoute={navigateRoute}
                    refreshFavoris={refreshFavoris}
                />}
                <Favoris
                    phone={phone}
                    zoom={zoom}
                    handleFavoris={handleFavoris}
                    navigateRoute={navigateRoute}
                    refreshFavoris={refreshFavoris}
                />
            </div>
        </Zoom>
    )
}