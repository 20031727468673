import * as React from 'react';
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { types } from './listOfType';
import { findIcone, findImg, FormatUrl, TransitionLeft, translateFormat, translateTitle } from '../../helper/utils';
import { apiFetch } from '../../Security/apiFetch';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import iconeJeux from '../../assets/ressource/icone/icone_jeux.png'
import Search from './Search';
import { themes } from './listOfTheme';

let debounce = null
export default function Type({
    zoom,
    setZoom,
    setText,
    setSeverity,
    setTransition,
    setOpenSnackbar,
    findPosition,
    setOpenBackdrop,
    navigateRoute,
    setBreadcrumb,
    setTheme,
    phone,
}) {
    document.title = 'Espace Salarié - Ressources'
    const list = types
    const { id } = useParams()
    const [ressources, setRessources] = React.useState([])
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')

    React.useEffect(() => {
        (async () => {
            setBreadcrumb(['Ressources bien-être', id])
            setTheme(id)
            setOpenBackdrop(true)
            const getRessources = await apiFetch(`/ressources?theme=${id}`)
            if (getRessources && getRessources.length) {
                setRessources(getRessources)
                setZoom(true)
            } else {
                setText('Erreur lors de la récupération des ressources !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                if (getRessources === '404') return navigateRoute('/error')
                navigateRoute(-1)
                findPosition()
            }
            setOpenBackdrop(false)
        })()
        return () => setZoom(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSearch = async (e) => {
        e && e.preventDefault()
        setSearchValue(e.target.value)
        clearTimeout(debounce)
        if (e.target.value.length > 1) {
            debounce = setTimeout(async () => {
                setOpenBackdrop(true)
                const getRessources = await apiFetch(`/ressources?theme=${id}&mot_cle=${e.target.value}`)
                setRessources(getRessources)
                setOpenBackdrop(false)
                setSearch(e.target.value)
            }, 500)
        }
        if (e.target.value.length === 0) {
            setOpenBackdrop(true)
            const getRessources = await apiFetch(`/ressources?theme=${id}`)
            if (getRessources && getRessources.length) {
                setRessources(getRessources)
                setZoom(true)
            } else {
                setText('Erreur lors de la récupération des ressources !')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                if (getRessources === '404') return navigateRoute('/error')
                navigateRoute(-1)
                findPosition()
            }
            setOpenBackdrop(false)
            setSearch('')
        }
    }
    const handleDelete = async () => {
        setOpenBackdrop(true)
        const getRessources = await apiFetch(`/ressources?theme=${id}`)
        if (getRessources && getRessources.length) {
            setRessources(getRessources)
            setZoom(true)
        } else {
            setText('Erreur lors de la récupération des ressources !')
            setSeverity('error')
            setTransition(() => TransitionLeft)
            setOpenSnackbar(true)
            if (getRessources === '404') return navigateRoute('/error')
            navigateRoute(-1)
            findPosition()
        }
        setOpenBackdrop(false)
        setSearch('')
        setSearchValue('')
    }

    return (
        <Zoom in={zoom} timeout={700} style={{ transitionDelay: '200ms' }}>
            <div>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                    <div style={{maxWidth: 1500, margin: 'auto', height: 60, textAlign: 'center'}}>
                        <TextField
                            id="search"
                            label="Rechercher des ressources par mots clés"
                            variant="outlined"
                            name='search'
                            value={searchValue}
                            onChange={handleSearch}
                            sx={{width: 700, mb: 3}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton type='submit'>
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </div>
                </Zoom>
                <Zoom in={zoom} timeout={700} style={{ transitionDelay: '400ms' }}>
                    <div style={{width: '100%', marginTop: 100, backgroundColor: '#EBF2F5', height: 250, display: 'table'}}>
                        <div style={{maxWidth: 1200, position: 'relative', margin: 'auto'}}>
                            <img src={findImg(id)} alt={id} width={230} style={{marginTop: -90}} />
                            <div
                                style={{
                                    position: phone ? 'relative' : 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    marginTop: phone ? 100 : 45,
                                    marginLeft: phone && 10,
                                }}
                            >
                                <Typography
                                    variant='h4'
                                    color='primary'
                                >
                                    {translateTitle(id)}
                                </Typography>
                                <Typography>
                                    {themes.find(item => item.title === id).description}
                                </Typography>
                            </div> 
                        </div>
                    </div>
                </Zoom>
                {!search && <Typography style={{maxWidth: 1000, margin: '50px auto', textAlign: 'center'}} color='primary' variant='h6'>
                    Comment souhaitez-vous explorer ce sujet ?
                </Typography>}
                {!search ? <Zoom in={zoom} timeout={700} style={{ transitionDelay: '800ms' }}>
                    <div
                        style={{
                            maxWidth: 1700,
                            margin: 'auto',
                            display: phone ? 'block' : 'flex',
                            justifyContent: 'center',
                            marginBottom: 30
                        }}
                    >
                        {list.length && list.map((item, i) => {
                            return ressources.find(ressource => ressource.format === item) &&
                            <Box
                                key={i}
                                variant='contained'
                                color='secondary'
                                onClick={() => navigateRoute(`/ressources/${id}/${FormatUrl(item)}`)}
                                sx={{
                                    mr: {xl: 5, lg: 2, md: 1, sm: 1, xs: 1},
                                    margin: phone && 'auto',
                                    width: 300,
                                    height: 250,
                                    backgroundColor: '#ACCDD8',
                                    textAlign: 'center',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    mt: {xs: 2}
                                }}
                            >
                                 <div
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                >
                                    <Typography color='primary' sx={{fontWeight: 'bold', mb: 3}}>{translateFormat(item)}</Typography>
                                    <img src={findIcone(item)} alt={item} width={100} style={{zIndex: 9999}} />
                                </div>
                            </Box>
                        })}
                        {id === 'No stress' &&
                        <Box
                            variant='contained'
                            color='secondary'
                            onClick={() => navigateRoute(`/ressources/${id}/wordle`, {state: '/ressources/:id/wordle'})}
                            sx={{
                                mr: {xl: 5},
                                margin: phone && 'auto',
                                width: 300,
                                height: 250,
                                backgroundColor: '#ACCDD8',
                                textAlign: 'center',
                                cursor: 'pointer',
                                position: 'relative',
                                mt: {xs: 2}
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            >
                                <Typography color='primary' sx={{fontWeight: 'bold', mb: 3}}>NOS JEUX</Typography>
                                <img src={iconeJeux} alt={'jeux'} width={100} style={{zIndex: 9999}} />
                            </div>
                        </Box>}
                    </div>
                </Zoom> : 
                <Search search={search} handleSearchNavigate={handleSearch} zoom={zoom} ressources={ressources} handleDelete={handleDelete} />}
            </div>
        </Zoom>
    )
}